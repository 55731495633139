<template>
    <div>
        <div class="top">
            <div class="topleft">
                <el-breadcrumb separator="/">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{$t('home')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{$t('grzx')}}</el-breadcrumb-item>
                    <el-breadcrumb-item>设置/修改支付密码</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="topright">
            </div>
        </div>
        <div class="cont">
            <div style="width:500px;">
                <el-form ref="form" :model="form" label-width="120px">
                    <el-form-item v-if="this.user.user.payPass != null" :label="$t('lmm')">
                        <el-input type="password" v-model="form.oldPass"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('xmm')">
                        <el-input type="password" v-model="form.newPass"></el-input>
                    </el-form-item>
                    <el-form-item :label="$t('qrxmm')">
                        <el-input type="password" v-model="renewPass"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">{{$t('baoc')}}</el-button>
                    </el-form-item>
                </el-form>
            </div>
            
        </div>
    </div>
</template>
<script>
import { encrypt } from '@/util/rsaEncrypt'
import apiurl from "@/api/sys"
import _api from "@/api/index"
export default {
    data(){
        return{
            renewPass:null,
            form:{oldPass:null,newPass:null},
        }
    },
    created() {
        this.user=JSON.parse(localStorage.getItem("userinfo"))
    },
    methods:{
       
        onSubmit(){
            if(this.form.newPass=="" || this.form.newPass==null){
                this.$notify.error({
                    title:this.$t('error'),
                    message:this.$t('qsrxmm')
                })
                return
            }
            if(this.form.newPass!=this.renewPass){
                this.$notify.error({
                    title:this.$t('error'),
                    message:this.$t('llmmbyz')
                })
                return
            }
            _api.post(apiurl.updatePayPass,{
                oldPass:this.form.oldPass,
                newPass:this.form.newPass
            }).then(res=>{
                this.$notify.success({
                    title:this.$t('success'),
                    message:this.$t('xgcg')
                })
                this.$router.push("/login")
            }).catch(err=>{
                this.$notify.error({
                    title:this.$t('error'),
                    message:err.data.message
                })
            })
        }
    }
}
</script>